<template>
  <a-card :bordered="false" class="card-area">

    <common-table
      ref="table"
      path="feedback_type"
      :columns="columns">

      <template slot="search">
        <a-button ghost type="primary" @click="add">新增</a-button>
      </template>

      <template slot="operation" slot-scope="{record}">
        <action-edit @click="edit(record)"></action-edit>
        <action-delete @click="remove(record)"></action-delete>
      </template>

    </common-table>

    <feedback-type-edit ref="feedbackTypeEdit" @success="getList"></feedback-type-edit>

  </a-card>

</template>
<script>

  import FeedbackTypeEdit from './FeedbackTypeEdit'

  export default {
    components: {
      FeedbackTypeEdit,
    },
    data () {
      return {
      }
    },
    created () {
      this.getList()
    },
    computed: {
      columns () {
        return [
          {
            title: '反馈类型',
            dataIndex: 'name',
          },
          {
            title: '排序',
            dataIndex: 'sort'
          },
          {
            title: '操作',
            dataIndex: 'operation',
            scopedSlots: {customRender: 'operation'},
          }
        ]
      }
    },
    methods: {
      add(){
        this.$refs.feedbackTypeEdit.show()
      },
      edit (record) {
        this.$refs.feedbackTypeEdit.show(record)
      },
      remove (record) {
        var that = this
        this.$confirm({
          title: '是否删除该类型?',
          content: '该操作不可撤销',
          centered: true,
          onOk () {
            that.$delete('feedback_type/' + record.id).then(() => {
              that.$message.success('删除成功')
              that.getList()
            })
          }
        })
      },

      getList () {
        this.$refs.table.getData()
      },

    }
  }
</script>
